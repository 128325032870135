:root {
  --foreground: hsl(0, 0%, 0%, 70%);

  --hover: hsl(0, 0%, 85%);
  --pill: hsl(0, 0%, 90%);

  --button: hsl(0, 0%, 93%);
  --buttonHover: hsl(0, 0%, 85%);

  --background: hsl(0, 0%, 95%);
  --backgroundFar: hsl(0, 0%, 75%);

  --inputBackground: #444;
  --inputForeground: white;

  --borderLight: hsl(0, 0%, 85%);
  --borderDark: hsl(0, 0%, 75%);

  --header: hsl(0, 0%, 85%);
  --headerColor: hsl(0, 0%, 0%, 70%);
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground: hsla(0, 0%, 100%, 95%);

    --hover: hsl(0, 0%, 50%);
    --pill: hsl(0, 0%, 10%);

    --button: hsl(0, 0%, 30%);
    --buttonHover: hsl(0, 0%, 50%);

    --background: hsl(0, 0%, 25%);
    --backgroundFar: hsl(0, 0%, 12%);

    --inputBackground: hsl(0, 0%, 15%);
    --inputForeground: white;

    --borderLight: hsl(0, 0%, 55%);
    --borderDark: hsl(0, 0%, 15%);

    --header: hsl(0, 0%, 35%);
    --headerColor: white;
  }
}


html, body {
  font-size: 16px;
}

html {
  background: var(--backgroundFar);
  color: var(--foreground);
}

code {
  font-family: 'Inconsolata';
  font-weight: 400;
  margin: 0;
}
pre {
  padding: 0;
  margin: 0;
}

input {
  color: var(--inputForeground);
  background: var(--inputBackground);
  border: none;
  padding: 0.5em 1em;
  font-size: 17px;
}
textarea {
  border-radius: 0.8rem;
  color: var(--inputForeground);
  font-family: 'Inconsolata';
  background: var(--inputBackground);
  border: none;
  padding: 1em;
  font-size: 17px;
}

.page-header {
  padding: 1em;
  display: flex;
  gap: 0.25em;
  justify-content: flex-end;
  background: var(--background);
}

.page-footer {
  text-align: center;
  padding: 1em;
  font-size: 0.7em;
}
.page-footer a {
  color: var(--foreground);
}

.page-main {
  padding: 1em;
}

.sides {
  display: grid;
  grid-template-columns: 1fr 1em 1fr;
  grid-template-areas: "left . right";
}

.side-left {
  grid-area: left;
}
.side-right {
  grid-area: right;
}
.side-right .sticky-container {
  position: sticky;
  top: 1em;
}

section.data main textarea {
  width: 100%;
  box-sizing: border-box;
}

code.expressionEvaluation.true {
  color: hsl(150deg 70% 60%);
}
code.expressionEvaluation.false {
  color: hsl(30deg 70% 60%);
}
code.expressionEvaluation.number {
  color: hsl(330deg 70% 60%);
}
code.expressionEvaluation {
  color: hsl(210deg 70% 60%);
}
code.expressionEvaluation.failure {
  color: hsl(0deg 70% 60%);
}
code.expressionEvaluation.failure.json, code.expressionEvaluation.failure.parse {
  color: hsl(0deg 20% 60%);
}

.buttons button svg {
  display: inline;
  width: 24px;
  height: 24px;
}
.buttons button {
  display: flex;
  justify-items: center;
  display: inline;
  margin: 0.2em;
  padding: 0.2em 0.5em;
  border-radius: 4px;
  cursor: pointer;
}
.buttons button.add-cel {
  color:hsl(210deg 20% 60%);
  background: hsl(210deg 20% 30%);
  border: hsl(210deg 20% 40%);
}
.buttons button.add-rets {
  color:hsl(120deg 20% 60%);
  background: hsl(120deg 20% 30%);
  border: hsl(120deg 20% 40%);
}

code.output {
  display: grid;
  grid-template-columns: auto 1fr;
}

a, a:visited {
  color: var(--foreground);
}
