.edit-rule-dialog {
    width: calc(100% - 8em);
    height: calc(100% - 8em);
    background: var(--background);
    color: var(--foreground);
    border-radius: 1em;
    border: none;
}
.edit-rule-dialog .action-buttons {
    display: flex;
    gap: 0.5em;
    justify-content: flex-end;
}

.edit-rule-dialog::backdrop {
    backdrop-filter: blur(2rem) brightness(80%);
}
.edit-rule-dialog > div {
    margin: 1em 0;
}
.edit-rule-dialog label {
    display: block;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0.25em;
}
.edit-rule-dialog input, dialog textarea {
    box-sizing: border-box;
    display: block;
    width: 100%;
    border-radius: 0.5em;
}

.rule-order {
    font-weight: bold;
}
