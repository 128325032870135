button.standard-button {
  display: flex;
  border-radius: 0;
  border: none;
  background: var(--button);
  color: var(--foreground);
  padding: 0.5em;
  font-size: 16px;
  cursor: pointer;
  border-radius: 0.5rem;
  position: relative;
  z-index: 1;
  box-shadow: var(--buttonBoxShadow);
  border: 1px solid var(--borderDark);
  transition: background 250ms ease-out, color 250ms ease-out;
}
button.standard-button:hover, button.standard-button:active {
  background: var(--buttonHover);
  border-color: #666;
}

button.standard-button .icon {
  flex: none;
  width: 1em;
  padding-right: 0.25em;
}

button.standard-button:disabled, button.standard-button:disabled:hover, button.standard-button:disabled:active {
  opacity: 0.5;
  background: var(--button);
  cursor: not-allowed;
}
